import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Blog({ data, location }) {
  const { posts } = data.blog
  const HeaderImage = ({ headerImage, headerImageDescription }) =>
    headerImage ? (
      <GatsbyImage
        image={headerImage.childImageSharp.gatsbyImageData}
        alt={headerImageDescription}
      ></GatsbyImage>
    ) : null
  return (
    <Layout location={location}>
      <SEO title="Development blog"></SEO>
      {posts.map(post => (
        <article key={post.id}>
          <Link
            to={`/blog${post.fields.slug}`}
            style={{ textDecoration: `none` }}
          >
            <h2>{post.frontmatter.title}</h2>
          </Link>
          <HeaderImage
            headerImage={post.frontmatter.headerImage}
            headerImageDescription={post.frontmatter.headerImageDescription}
          ></HeaderImage>
          <p>
            {post.excerpt}{" "}
            <Link
              to={`/blog${post.fields.slug}`}
              style={{ textDecoration: `none` }}
            >
              continue reading
            </Link>
          </p>
        </article>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      sort: {
        fields: [frontmatter___date, frontmatter___title]
        order: [DESC, DESC]
      }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true, formatString: "MMMM DD, YYYY")
          title
          author
          headerImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          headerImageDescription
        }
        excerpt
        id
      }
    }
  }
`
